import { ApplyColumnStateParams, ColumnApi, GridApi, GridOptions } from "ag-grid-enterprise";
import { filter, Subscription } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { GridStateCachingService } from "../../services/grid-state-caching.service";
import { ColDef, ColumnState, FirstDataRenderedEvent, GridReadyEvent, RowGroupOpenedEvent } from "ag-grid-community";
import { AGGridHelperService } from "../../services/ag-grid-helper-service";
import { CachedGridState } from "../../models/cached-grid-state.model";
import { ChangeDetectorRef } from "@angular/core";

export class GridWithCachingBaseClass {

  public gridOptions: GridOptions;
  public gridAPI: GridApi
  public columnAPI: ColumnApi;
  public customCacheData: any;
  public gridCacheKey: string;
  public columnDefs: ColDef[];
  public subscription = new Subscription();
  public originalState: CachedGridState;
  public stateIsDifferentFromOriginal: boolean = false;

  columnEvents: Array<string> = ["columnVisible", "columnPinned", "columnResized", "columnMoved", "columnValueChanged", "columnPivotModeChanged", "columnPivotChanged",
    "columnGroupOpened", "displayedColumnsChanged", "sortChanged", "filterChanged", "rowGroupOpened"]
  constructor(public gridStatePageCachingService: GridStateCachingService,
    public internalRouter: Router, public gripHelperService: AGGridHelperService,
    public changeDetection: ChangeDetectorRef
  ) {
    this.gridCacheKey = "";

    this.subscription.add(this.internalRouter.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((routerEvent: any) => {      
        this.saveCurrentGridState();
    }));
  }

  public onGridReadyBase(params: GridReadyEvent) {
    if (!this.gridAPI) {
      this.gridAPI = params.api;
    }

    if (!this.columnAPI) {
      this.columnAPI = params.columnApi;
    }

    if (!this.columnDefs) {
      this.columnDefs = this.gridOptions.columnDefs;
    }
    this.gridAPI.setColumnDefs(this.columnDefs);
    this.gridAPI.sizeColumnsToFit();

    window.onresize = () => {
      this.gridAPI.sizeColumnsToFit();
    }

    this.gridAPI.addGlobalListener((type: any, event: any) => {
      if (this.columnEvents.some(x => x == type)) {
        //We need to track with groups are opened on our own.
        this.checkIfStateHasChanged();
      }
    });
    this.gridOptions.onFirstDataRendered = this.onFirstDataRenderedBase.bind(this);
  }

  public onFirstDataRenderedBase(params: FirstDataRenderedEvent): void {    
    this.originalState = this.getCurrentGridState();
  this.restoreCachedGridState();
  }

  private checkIfStateHasChanged(): void {
    if (this.originalState) {
      this.saveCurrentGridState();
      let stringifiedOriginalFilterModel = JSON.stringify(this.originalState.filterModel);
      let stringifiedCurrentFilterModel = JSON.stringify(this.gridAPI.getFilterModel());

      let stringifiedOriginalColumnState = JSON.stringify(this.originalState.columnStates);
      let stringifiedCurrentColumnState = JSON.stringify(this.columnAPI.getColumnState());

      let stringifiedOriginalCustomCacheData = JSON.stringify(this.originalState.customCacheData);
      let stringifiedCurrentCustomCacheData = JSON.stringify(this.customCacheData);

      let currentExpandedNodeIds = this.getExpandedRowNodeIds();
      if (stringifiedOriginalFilterModel != stringifiedCurrentFilterModel
        || stringifiedOriginalColumnState != stringifiedCurrentColumnState
        || stringifiedOriginalCustomCacheData != stringifiedCurrentCustomCacheData
        || this.originalState.expandedRows.some(x => !this.gridAPI.getRowNode(x).expanded)
        || currentExpandedNodeIds.some(x => !this.originalState.expandedRows.some(y => y == x))
      ) {
        this.stateIsDifferentFromOriginal = true;
      }
      else {
        this.stateIsDifferentFromOriginal = false;
      }

      //Force immediate bubbling up of binding change through the components.
      this.changeDetection.detectChanges();
    }
  }

  public restoreCachedGridState() {
    let priorGridState = this.gridStatePageCachingService.fetchPageFilterSettings(this.gridCacheKey);
    if (priorGridState != null) {
      this.applyCachedGridState(priorGridState);
      this.saveCurrentGridState();
      this.stateIsDifferentFromOriginal = false;
      //Force immediate bubbling up of binding change through the components.
      this.changeDetection.detectChanges();
    }

  }

  public applyCachedGridState(cachedState: CachedGridState) {

    if (this.columnAPI != null) {

      //Restore column state (visible, order, size, etc...)
      this.columnAPI.applyColumnState({ applyOrder: true, state: cachedState.columnStates } as ApplyColumnStateParams);
    }

    if (this.gridAPI != null) {
      //Restore filters
      this.gridAPI.setFilterModel(cachedState.filterModel);

      //For some reason this does not work
      //this.gridAPI.collapseAll();

      //Using set expanded gets us the pretty animations but can be less performant depending on
      //the number of rows needing touched. If performance becomes an issue, can instead just set the
      //expanded property directly for all the nodes, and then call onGroupExpandedOrCollapsed() once they've
      //all been set accordingly.

      //collapse any open nodes
      this.gridAPI.forEachNode((x) => { x.setExpanded(false); });

      //If there were any expanded rows, re-expand them
      if (cachedState.expandedRows && cachedState.expandedRows.length > 0) {
        cachedState.expandedRows.forEach((rowId) => {
          this.gridAPI.getRowNode(rowId).setExpanded(true);
        })
      }

      //this.gridAPI.onGroupExpandedOrCollapsed();
    }
    this.customCacheData = cachedState.customCacheData;
  }

  private getExpandedRowNodeIds(): Array<string> {
    let expandedRows: Array<string> = [];
    if (this.gridAPI != null && this.columnAPI != null) {
      //if there are any grouped columns, run through the row nodes and see which ones are expanded
      if (this.columnDefs.some(x => x.cellRenderer == "agGroupCellRenderer")) {
        this.gridAPI.forEachNode((node) => {
          if (node.expanded) {
            expandedRows.push(node.id);
          }
        });
      }
    }

    return expandedRows;
  }

  public getCurrentGridState(): CachedGridState {
    let currentFilterModel: any = null;
    let currentColumnStates: ColumnState[] = [];
    if (this.gridAPI != null) {
      currentFilterModel = this.gridAPI.getFilterModel();      
    }
    if (this.columnAPI != null) {
      currentColumnStates = this.columnAPI.getColumnState();
    }

    let cachedGridState = {
      columnStates: currentColumnStates,
      filterModel: currentFilterModel,
      expandedRows: this.getExpandedRowNodeIds(),
      customCacheData: this.customCacheData
    } as CachedGridState;
    return cachedGridState;
  }

  public saveCurrentGridState() {
    let cachedGridState = this.getCurrentGridState();
    this.gridStatePageCachingService.savePageFilterSettings(this.gridCacheKey, cachedGridState);
  }

}
