import { Injectable } from "@angular/core";
import { CachedGridState } from "../models/cached-grid-state.model";

@Injectable({
  providedIn: 'root'
})
export class GridStateCachingService {

  gridCachedFilterModels: Map<string, CachedGridState> = new Map<string, CachedGridState>();

  constructor() {

  }

  public clearCache() {
    this.gridCachedFilterModels.clear();
  }

  public savePageFilterSettings(gridKey: string, gridState: CachedGridState) {
    this.gridCachedFilterModels.set(gridKey, gridState);
  }

  public fetchPageFilterSettings(gridKey: string): CachedGridState {

    if (this.gridCachedFilterModels.has(gridKey)) {
      return this.gridCachedFilterModels.get(gridKey);
    }
    else {
      return null;
    }
  }
}
