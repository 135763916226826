import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { CachedGridState } from '../../models/cached-grid-state.model';
import { AGGridHelperService } from '../../services/ag-grid-helper-service';
import { GridStateCachingService } from '../../services/grid-state-caching.service';
import { GridWithCachingBaseClass } from '../base-classes/grid-with-caching-base-class.model';


@Component({
  selector: 'caching-ag-grid',
  templateUrl: './caching-ag-grid.component.html',
  styleUrls: ['./caching-ag-grid.component.scss']
})
export class CachingAgGridComponent extends GridWithCachingBaseClass implements OnInit, OnDestroy {

  @Input() gridHeight: string = "";
  @Output() gridHeightChange = new EventEmitter<string>();

  @Input() override gridOptions: GridOptions;
  @Output() gridOptionsChange = new EventEmitter<GridOptions>();

  @Input() public rowData: any[];
  @Output() public rowDataChange = new EventEmitter<any[]>();

  @Input() override customCacheData: any;
  @Output() public customCacheDataChange = new EventEmitter<any>();

  @Input() public providedOnGridReadyMethod: Function;
  @Input() public frameworkComponents: any;

  @Input() override gridCacheKey: string;

  @Output() public cachedStateRestored = new EventEmitter<CachedGridState>();


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnDefs']) {
      let bla = 0;
    }
  }

  constructor(gridStatePageCachingService: GridStateCachingService,
    router: Router,
    gripHelperService: AGGridHelperService,
    changeDetection: ChangeDetectorRef
  ) {
    super(gridStatePageCachingService, router, gripHelperService, changeDetection);
  }

  public onGridReady(params: GridReadyEvent) {
    this.providedOnGridReadyMethod(params);
    this.onGridReadyBase(params);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  clearCachedState() {
    if (this.hasCachedState()) {
      this.gridStatePageCachingService.gridCachedFilterModels.delete(this.gridCacheKey);
      this.originalState = null;
    }
  }

  hasCachedState(): boolean {
    return this.gridStatePageCachingService.gridCachedFilterModels.has(this.gridCacheKey);
  }

  getCachedState(): CachedGridState {
    let cachedState = this.hasCachedState() ? this.gridStatePageCachingService.gridCachedFilterModels.get(this.gridCacheKey) : null;
    return cachedState;
  }

  resetOriginalGridState() {
    if (this.originalState) {
      this.applyCachedGridState(this.originalState);
      this.gridAPI.sizeColumnsToFit();
    }
  }

  setColumnDefs(columnDefs: ColDef[]): void {
    this.columnDefs = columnDefs;
    if (this.gridAPI) {
      this.gridAPI.setColumnDefs(this.columnDefs);
      this.gridAPI.sizeColumnsToFit();
    }
  }

}
