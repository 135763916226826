<div>
  <!--<div class="tooltip-wrapper">
  <button uiLibButton
          [color]="'PRIMARY' | buttonColorEnumPipe"
          [size]="'MEDIUM' | buttonSizeEnumPipe"
          [shape]="'ROUNDED' | buttonShapeEnumPipe" Mana
          [style]="'SOLID' | buttonStyleEnumPipe"
          (click)="saveCurrentGridState()">
    Save State
  </button>-->
  <!--<app-popper-tool-tip screenReaderText="Save State of the data grid"></app-popper-tool-tip>-->
  <!--</div>
  <div class="tooltip-wrapper">
    <button uiLibButton
            [color]="'PRIMARY' | buttonColorEnumPipe"
            [size]="'MEDIUM' | buttonSizeEnumPipe"
            [shape]="'ROUNDED' | buttonShapeEnumPipe" Mana
            [style]="'SOLID' | buttonStyleEnumPipe"
            (click)="restoreCachedGridState()">
      Restore State
    </button>-->
  <!--<app-popper-tool-tip screenReaderText="Restore Saved State of the data grid"></app-popper-tool-tip>
  </div>-->
  <div class="tooltip-wrapper reset-button">
    <button uiLibButton
            [disabled]="!stateIsDifferentFromOriginal"
            [color]="'PRIMARY' | buttonColorEnumPipe"
            [size]="'MEDIUM' | buttonSizeEnumPipe"
            [shape]="'ROUNDED' | buttonShapeEnumPipe" Mana
            [style]="'SOLID' | buttonStyleEnumPipe"
            (click)="resetOriginalGridState()">
      Reset Grid State
    </button>
    <app-popper-tool-tip screenReaderText="Reset the data grid to its initial state"></app-popper-tool-tip>
  </div>
  <div [ngStyle]="{'height': gridHeight}">
    <ag-grid-angular style="width: 100%; height: 100%"
                     class="ag-theme-alpine"
                     [gridOptions]="gridOptions"
                     [components]="frameworkComponents"
                     [rowData]="rowData"
                     [rowSelection]="'multiple'"
                     [animateRows]="true"
                     (gridReady)="onGridReady($event)"></ag-grid-angular>
  </div>
</div>
