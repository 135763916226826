import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { UiLibrarySharedModule } from 'ui-library-shared';
import { IconButtonComponent } from '../popper/components/icon-button/icon-button.component';
import { IconButtonColorEnumPipe } from '../popper/components/icon-button/icon-button-color-enum.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminFunctionsCellComponent } from './components/renderers/admin-functions-cell/admin-functions-cell.component';
import { InlineEditorCellRendererComponent } from './components/renderers/inline-editor-cell-renderer/inline-editor-cell-renderer.component';
import { CASNumberCellEditorComponent } from './components/editors/cas-number-cell-editor/cas-number-cell-editor.component';
import { SettableTextCellEditorComponent } from './components/editors/settable-text-cell-editor/settable-text-cell-editor.component';
import { AGGridHelperService } from './services/ag-grid-helper-service';
import { NumericCellEditorComponent } from './components/editors/numeric-cell-editor/numeric-cell-editor.component';
import { OpenDeleteCellComponent } from './components/renderers/open-delete-cell/open-delete-cell.component';
import { OpenRollbackCellComponent } from './components/renderers/open-rollback-cell/open-rollback-cell.component';
import { EditHistoryCellComponent } from './components/renderers/edit-history-cell/edit-history-cell.component';
import { AlwaysActiveCheckboxCellComponent } from './components/renderers/always-active-checkbox-cell/always-active-checkbox-cell.component';
import { RadioButtonCellEditorComponent } from './components/editors/radio-button-cell-editor/radio-button-cell-editor.component';
import { NgSelectEditorComponent } from './components/editors/ng-select-editor/ng-select-editor.component';
import { RadioButtonCellRendererComponent } from './components/renderers/radio-button-cell-renderer/radio-button-cell-renderer.component';
import { EditDeleteCellComponent } from './components/renderers/edit-delete-cell/edit-delete-cell.component';
import { ValidatedFieldCellRendererComponent } from './components/renderers/validated-field-cell-renderer/validated-field-cell-renderer.component';
import { SafeHtmlPipe } from '../common/pipes/safe-html.pipe';
import { AmendCellComponent } from './components/renderers/amend-cell/amend-cell.component';
import { SendActivationEmailCellComponent } from './components/renderers/send-activation-email-cell/send-activation-email-cell.component';
import { HistoryCellRendererComponent } from './components/renderers/history-cell-renderer/history-cell-renderer.component';
import { DateCellEditorComponent } from './components/editors/date-cell-editor/date-cell-editor.component';
import { DownloadXmlCellComponent } from './components/renderers/download-xml-cell/download-xml-cell.component';
import { ImpersonateUserCellComponent } from './components/renderers/impersonate-user-cell/impersonate-user-cell.component';
import { UnlockUserCellComponent } from './components/renderers/unlock-user-cell/unlock-user-cell.component';
import { UserRoleCellEditorComponent } from './components/editors/user-role-cell-editor/user-role-cell-editor.component';
import { DateCellRendererComponent } from './components/renderers/date-cell-renderer/date-cell-renderer.component';
import { HeaderButtonRendererComponent } from './components/renderers/header-button-renderer/header-button-renderer.component';
import { SettableCheckboxEditorComponent } from './components/editors/settable-checkbox-editor/settable-checkbox-editor.component';
import { CheckboxRendererComponent } from './components/renderers/checkbox-renderer/checkbox-renderer.component';
import { CachingAgGridComponent } from './components/caching-ag-grid/caching-ag-grid.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { FFPopperModule } from '../popper/ff-popper.module';

@NgModule({
  declarations: [
    AdminFunctionsCellComponent,
    InlineEditorCellRendererComponent,
    CASNumberCellEditorComponent,
    SettableTextCellEditorComponent,
    NumericCellEditorComponent,
    RadioButtonCellEditorComponent,
    NgSelectEditorComponent,
    OpenDeleteCellComponent,
    OpenRollbackCellComponent,
    AlwaysActiveCheckboxCellComponent,
    EditHistoryCellComponent,
    EditDeleteCellComponent,
    AmendCellComponent,
    RadioButtonCellRendererComponent,
    EditHistoryCellComponent,
    SafeHtmlPipe,
    ValidatedFieldCellRendererComponent,
    SettableCheckboxEditorComponent,
    SendActivationEmailCellComponent,
    HistoryCellRendererComponent,
    DateCellEditorComponent,
    DownloadXmlCellComponent,
    ImpersonateUserCellComponent,
    UnlockUserCellComponent,
    UserRoleCellEditorComponent,
    DateCellRendererComponent,
    HeaderButtonRendererComponent,
    CheckboxRendererComponent,
    CachingAgGridComponent,
  ],
  imports: [
    CommonModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    IconSpriteModule,
    UiLibrarySharedModule,
    NgSelectModule,
    FFPopperModule
  ],
  exports: [
    AgGridModule,
    AdminFunctionsCellComponent,
    InlineEditorCellRendererComponent,
    CASNumberCellEditorComponent,
    SettableTextCellEditorComponent,
    SettableCheckboxEditorComponent,
    NumericCellEditorComponent,
    RadioButtonCellEditorComponent,
    RadioButtonCellRendererComponent,
    NgSelectEditorComponent,
    EditHistoryCellComponent,
    EditDeleteCellComponent,
    AlwaysActiveCheckboxCellComponent,
    SafeHtmlPipe,
    ValidatedFieldCellRendererComponent,
    AmendCellComponent,
    SendActivationEmailCellComponent,
    DownloadXmlCellComponent,
    DateCellRendererComponent,
    HeaderButtonRendererComponent,
    CachingAgGridComponent
  ],
  providers: [
    AGGridHelperService
  ]
})
export class FFAgGridModule { }
