import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { IconButtonColorEnumPipe } from './components/icon-button/icon-button-color-enum.pipe';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { PopperToolTipComponent } from './components/popper-tool-tip/popper-tool-tip.component';

@NgModule({
  declarations: [
    IconButtonComponent,
    PopperToolTipComponent,
    IconButtonColorEnumPipe
  ],
  imports: [
    CommonModule,
    IconSpriteModule,
    NgxPopperjsModule
  ],
  exports: [
    IconButtonComponent,
    PopperToolTipComponent,
    IconButtonColorEnumPipe
  ],
  providers: [
  ]
})
export class FFPopperModule { }
